<!-- @Author: Yu_Bo -->
<template>
	<div class='message_center'>
		<div class="sec_wrap">
			<el-tabs class="tabs_msg" v-model="activeName" @tab-click="handleClick">
				<el-tab-pane label="全部消息" name="0"></el-tab-pane>
				<el-tab-pane v-for="(value, key) in tabList" :key="key" :label="value" :name="key"></el-tab-pane>
			</el-tabs>
		</div>
		<div class="sec_wrap mt20 p20">
			<el-form ref="params" :model="params" :inline="true" label-position="left" size='small'>
				<el-form-item props="notice">
					<el-input placeholder="请输入标题内容" suffix-icon="el-icon-search" v-model="params.notice" @change="search"
						:clearable="true">
					</el-input>
				</el-form-item>
			</el-form>
			<div class="table_wrap">
				<el-table ref="table1" key="table1" :data="list" v-loading="loading" style="width: 100%"
					header-cell-class-name="table-header" cell-class-name="cell-class">
					<template slot="empty">
						<empty-data img='no_img_b.png' text='暂无数据'></empty-data>
					</template>
					<!-- <el-table-column prop="" label="状态" width="80">
						<template slot-scope="scope">
							<div class="read_text" v-if="scope.row.is_read == 2"><span class="text_span_no"></span><span
									class="text_txt">未读</span></div>
							<div class="read_text" v-if="scope.row.is_read == 1"><span class="text_span"></span><span
									class="text_txt">已读</span></div>
						</template>
					</el-table-column> -->
					<el-table-column prop="notice" label="标题内容" show-overflow-tooltip>
						<!-- <template slot-scope="scope">
							<div v-html="scope.row.notice"></div>
						</template> -->
					</el-table-column>
					<el-table-column prop="create_time" label="发送时间" width="160">
					</el-table-column>
					<el-table-column prop="type_text" label="类型" width="80">
					</el-table-column>
					<el-table-column label="操作" width="80">
						<template slot-scope="scope">
							<el-button type="text" size="small" @click="toDetail(scope.row.id)">
								查看
							</el-button>
						</template>
					</el-table-column>
				</el-table>
				<div class="page_box">
					<paging-page :total='total' align='right' @sizeChange='sizeChangeBtn'
						@currentChange='currentChangeBtn'></paging-page>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import EmptyData from '@/components/empty_data/empty_data.vue'//空数据
import PagingPage from '@/components/paging_page/paging_page.vue'//分页

export default {
	components: { EmptyData, PagingPage, },
	props: {},
	data() {
		return {
			tabList: {},
			activeName: '0',
			params: {},
			page: 1,
			limit: 10,
			total: 0,
			loading: false,
			list: [],
		}
	},
	computed: {},
	watch: {},
	created() {
		this.getParams()
		this.getList()
	},
	mounted() { },
	methods: {
		// tab切换
		handleClick(tab) {
			this.activeName = tab.name
			this.resetFrom()
		},
		search() {
			this.page = 1
			this.list = []
			this.total = 0
			this.getList()
		},
		resetFrom() {
			this.$refs.params.resetFields()
			this.search()
		},
		sizeChangeBtn(val) {
			this.limit = val
			this.page = 1
			this.getList();
		},
		currentChangeBtn(val) {
			this.page = val;
			this.getList();
		},
		getList() {
			var params = {
				page: this.page,
				limit: this.limit,
				type: this.activeName,
				...this.params
			}
			this.loading = true
			this.$personApi.getMessageList(params).then(res => {
				this.loading = false

				if (res.code == 1000) {
					this.list = res.result.data
					this.total = res.result.total
				} else {
					this.$errMsg(res.message)

				}
			})

		},
		getParams() {
			this.$personApi.messageParams().then(res => {
				if (res.code == 1000) {
					this.tabList = res.result.type
				} else {
					this.$errMsg(res.message)
				}
			})
		},
		toDetail(id) {

			this.$router.push({
				path: '/person/messageDetails',
				query: {
					id: id
				}
			})
		},
	},
}
</script>

<style lang='scss' scoped>
@import '../index.scss';

.message_center {}

::v-deep .tabs_msg {
	.el-tabs__header {
		margin: 0;
	}

	.el-tabs__nav {
		left: 20px;
		padding: 20px 0;
	}

	.el-tabs__item {
		font-size: 16px;
		height: 0;
		line-height: 0;
	}

	.el-tabs__nav-wrap::after {
		content: "";
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 1px;
		background-color: transparent;
		z-index: 1;
	}

	.el-tabs__active-bar {
		bottom: 6px;
	}
}

.mt20 {
	margin-top: 20px;
}

.read_text {
	display: flex;
	align-items: center;
	.text_span {
		display: block;
		width: 0.5rem;
		height: 0.5rem;
		border-radius: 50%;
		background: #c2c2c2;
	}
	.text_span_no {
		display: block;
		width: 0.5rem;
		height: 0.5rem;
		border-radius: 50%;
		background: #e00c25;
	}

	.text_txt {
		margin-left: 5px;
	}
}
</style>